import HeaderSection from "../../components/HeaderSection";
import UserComponent from "../../components/UserComponent";
import UserInfoComponent from "../../components/UserInfoComponent";
import ReminderComponent from "../../components/ReminderComponent";
import { tableContent1 } from "../../utils/constant";
import TransactionHeader from "../../components/TransactionHeader";
import TransactionComponent from "../../components/TransactionComponent";
import { useSearchParams } from "react-router-dom";
import NoData from "../../components/NoData";

export default function AccountPage(props) {

    const [query] = useSearchParams();
    const isNone = query.get('data') === 'none' ? true : false;

    return (
        <div className="bg-[#121212] pb-[90px]">
            <HeaderSection account={true} />
            <div className="flex flex-col px-[20px] lg:px-[150px] pt-[100px]">
                <div className="flex flex-col 2xl:flex-row w-full gap-[30px]">
                    <UserComponent />
                    <UserInfoComponent />
                    <ReminderComponent />
                </div>
                <div className="flex flex-col mt-10">
                    <p className="text-white text-[35px] font-bold">My Will:</p>
                </div>
            </div>
            <div className="overflow-x-auto">
                <TransactionHeader />
                {!isNone &&
                    tableContent1.map((item, index) => {
                        return (<div key={index}>
                            <TransactionComponent item={item} />
                        </div>)
                    })
                }
                {isNone && <div className="flex flex-col px-[20px] lg:px-[150px] min-w-[1560px] "><NoData /></div>}
                <div className="flex flex-col px-[20px] lg:px-[150px] ">
                    <div className="flex flex-col mt-10">
                        <p className="text-white text-[35px] font-bold">My Claims:</p>
                    </div>
                </div>
                <TransactionHeader />
                {!isNone &&
                    tableContent1.map((item, index) => {
                        return (<div key={index}>
                            <TransactionComponent item={item} />
                        </div>)
                    })
                }
                {isNone && <div className="flex flex-col px-[20px] lg:px-[150px] min-w-[1560px] "><NoData /></div>}
            </div>
        </div >
    )
}