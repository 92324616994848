import AvatarIcon from '../assets/icons/avatar.svg'
import Circle from '../assets/icons/Ellipse.svg'
import Pen from '../assets/icons/edit.svg'
export default function UserComponent(props) {

    return (
        <div className="flex bg-bg rounded-[16px] items-center justify-center">
            <div className="flex flex-col px-[80px] py-[38px] items-center justify-center relative">
                <button className='cursor-pointer'>
                    <img src={Circle} alt='ico' className='absolute top-[90px] left-[145px]' />
                    <img src={Pen} alt='ico' className='absolute top-[95px] left-[150px]' />
                </button>
                <img src={AvatarIcon} alt='ico' className="w-[75px] h-[75px]" />
                <p className="text-white text-[18px] font-semibold py-2 w-max">Wally Whill</p>
                <p className="text-white text-[14px]">Robertingaton</p>
            </div>
        </div>
    )
}