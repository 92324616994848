import TelegramIcon from '../../assets/icons/telegram.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedinIcon from '../../assets/icons/Linkedin.svg'
import FlashLogo from '../../assets/icons/flash.svg'
import Circle from '../../assets/icons/circle.svg'

const Footer = () => {
    return (
        <div className="flex flex-col min-h-[292px] bg-bg px-[20px] lg:px-[150px] pt-[50px] items-center justify-between">
            <div className='flex flex-col lg:flex-row items-start justify-between w-full'>
                <div className='flex flex-col items-center lg:items-start w-full lg: gap-[26px] py-[10px] lg:py-[70px] lg:w-[300px]'>
                    <div className='flex flex-row gap-2'>
                        <img src={FlashLogo} alt='ico' className='max-w-[40px] h-[40px]' />
                        <div className='flex text-white text-[19px] text-[700] justify-center items-center h-[40px]'>
                            <span>Flash Dead</span>
                        </div>
                    </div>
                    <div className='flex text-gray1 text-[15px] text-[400] '>
                        1st Dapps in the decentralized world to easily transfer your funds to your heirs.
                    </div>
                </div>
                <div className='flex flex-col w-full lg:w-max lg:flex-row justify-start lg:justify-between px-[80px] py-[10px] lg:py-[80px] gap-[40px] lg:gap-[90px]'>
                    <div className='flex flex-col gap-2 justify-start items-center lg:items-start leading-[27px]'>
                        <p className='text-white text-[17px] text-[500]'>How use Dead Wallet?</p>
                        <p className='text-gray1 text-[16px] text-[400]'>Read Gitbook</p>
                    </div>
                    <div className='flex flex-col gap-2 justify-start items-center lg:items-start leading-[27px]'>
                        <p className='text-white text-[17px] text-[500]'>Flash Wallet App</p>
                        <p className='text-gray1 text-[15px] text-[400]'>Google Play Store</p>
                        <p className='text-gray1 text-[15px] text-[400]'>Apple Store</p>
                    </div>
                    <div className='flex flex-col gap-2 justify-start items-center lg:items-start leading-[27px]'>
                        <p className='text-white text-[17px] text-[500]'>Links</p>
                        <p className='text-gray1 text-[15px] text-[400]'>Privacy Notice</p>
                        <p className='text-gray1 text-[15px] text-[400]'>Legal Notice</p>
                        <p className='text-gray1 text-[15px] text-[400]'>Audit</p>
                    </div>
                </div>
                <div className='flex flex-col justify-start w-full lg:w-max items-center lg:items-start py-[40px] lg:py-[80px] h-full'>
                    <div className='flex flex-col text-white text-[18px] gap-2 text-[500] justify-start leading-[27px]'>
                        <span>Get In Touch</span>
                        <div className='flex gap-2'>
                            <img src={TwitterIcon} alt='ico' />
                            <div className='relative flex '>
                                <img src={Circle} alt='ico' />
                                <img src={TelegramIcon} alt='ico' className='absolute top-2 left-2' />
                            </div>
                            <img src={LinkedinIcon} alt='ico' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center mb-5 text-white text-[12.8px] text-[400] tracking-tight'>
                Flash Dead © 2024- All Rights Reserved
            </div>
        </div>
    )
}

export default Footer;