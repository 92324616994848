import { useState } from "react";

import TabBar from "../../components/TabBar";
import TransactionComponent from "../../components/TransactionComponent";
import TransactionHeader from "../../components/TransactionHeader";
import DownloadWallet from "../../components/DownloadWallet";
import Directory from "../../components/Directory";
import HeaderSection from "../../components/HeaderSection";
import { tableContent1 } from "../../utils/constant";
import { tableContent2 } from "../../utils/constant";
import { tableContent3 } from "../../utils/constant";
import { tableContent4 } from "../../utils/constant";
import { ConfigProvider, DatePicker, theme } from 'antd';
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
export default function MainScene(props) {
    const [tabBar, setTabBar] = useState(0)
    const { darkAlgorithm } = theme;

    return (
        <div className="bg-[#121212]">
            <HeaderSection account={false} />
            <Directory />
            <div className="overflow-x-auto">
                <div className="bg-[#121212] w-full min-w-[1560px] px-[20px] lg:px-[140px] pt-[60px]">
                    <div className="bg-[#1B1B1B] rounded-[16px] w-full px-[32px] py-[24px]">
                        <p className="text-[16px] text-[#fff] font-Inter-Regular text-[600]">
                            Digital assets to delegate
                        </p>
                        <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
                            <div className="flex justify-between items-center">
                                <TabBar tabBar={tabBar} setTabBar={setTabBar} />
                                <RangePicker 
                                    style={{width:'250px',height:'60px',marginTop:'20px',borderRadius:'12px'}}
                                    defaultValue={[dayjs('2023/11/14', dateFormat), dayjs('2023/11/15', dateFormat)]}
                                    format={dateFormat}
                                />
                            </div>
                        </ConfigProvider>
                    </div>
                </div>
                {tabBar === 0 &&
                    <div className="">
                        <TransactionHeader />
                        {
                            tableContent1.map((item, index) => {
                                return (<div key={index}>
                                    <TransactionComponent item={item} />
                                </div>)
                            })
                        }
                    </div>
                }
                {tabBar === 1 &&
                    <div className="">
                        <TransactionHeader />
                        {
                            tableContent2.map((item, index) => {
                                return (<div key={index}>
                                    <TransactionComponent item={item} />
                                </div>)
                            })
                        }
                    </div>
                }
                {tabBar === 2 &&
                    <div className="">
                        <TransactionHeader />
                        {
                            tableContent3.map((item, index) => {
                                return (<div key={index}>
                                    <TransactionComponent item={item} />
                                </div>)
                            })
                        }
                    </div>
                }
                {tabBar === 3 &&
                    <div className="">
                        <TransactionHeader />
                        {
                            tableContent4.map((item, index) => {
                                return (<div key={index}>
                                    <TransactionComponent item={item} />
                                </div>)
                            })
                        }
                    </div>
                }
            </div>
            <DownloadWallet />
        </div >
    )
}