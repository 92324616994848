import { columns } from '../utils/constant';

export default function ClaimHeader(props) {
    return (
        <div className='flex flex-row justify-between min-w-[1560px] bg-[#121212] w-full px-[50px] 2xl:px-[180px] pt-[40px] pb-[16px]'>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 uppercase'>
                {columns[0].title}
            </p>

            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 uppercase'>
                {columns[1].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 uppercase'>
                {columns[2].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 uppercase'>
                {columns[3].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 uppercase'>
                {columns[4].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular text-[500] basis-2/12 text-center uppercase'>
                {columns[5].title}
            </p>

        </div>

    )
}