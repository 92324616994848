const TimePane = () => {
    return (
        <div className="flex w-full p-[60px] items-center justify-center">
            <div className="flex flex-col bg-bg rounded-[20px] w-[383px] py-3" >
                <div className="flex justify-center">
                    <p className="text-white text-[16px] font-Inter-Regular font-[500]">Claim Available in</p>
                </div>
                <div className="flex flex-col mt-6 gap-2 px-[40px]">
                    <div className="flex flex-row justify-center">
                        <div className="flex flex-col justify-center items-center gap-2">
                            <div className="flex w-[60px] h-[60px] font-Inter-Regular rounded-[8px] bg-[#111] items-center justify-center text-white font-[600] text-[24px]" >02</div>
                            <span className="font-Inter-Regular text-gray1 text-[14px] font-[500] uppercase">Day</span>
                        </div>
                        <div className="flex flex-col h-full gap-4 pt-4 px-1 justify-between">
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-2">
                            <div className="flex w-[60px] h-[60px] font-Inter-Regular rounded-[8px] bg-[#111] items-center justify-center text-white font-[600] text-[24px]" >12</div>
                            <span className="font-Inter-Regular text-gray1 text-[14px] font-[500] uppercase">Hour</span>
                        </div>
                        <div className="flex flex-col h-full gap-4 pt-4 px-1 justify-between">
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-2">
                            <div className="flex w-[60px] h-[60px] font-Inter-Regular rounded-[8px] bg-[#111] items-center justify-center text-white font-[600] text-[24px]" >40</div>
                            <span className="font-Inter-Regular text-gray1 text-[14px] font-[500] uppercase">Min</span>
                        </div>
                        <div className="flex flex-col h-full gap-4 pt-4 px-1 justify-between">
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                            <span className="rounded-full w-[5px] h-[5px] bg-gray1"></span>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-2">
                            <div className="flex w-[60px] h-[60px] font-Inter-Regular rounded-[8px] bg-[#111] items-center justify-center text-white font-[600] text-[24px]" >34</div>
                            <span className="font-Inter-Regular text-gray1 text-[14px] font-[500] uppercase">Sec</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimePane;