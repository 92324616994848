import { Button } from '@material-tailwind/react';
import UpRightImg from '../assets/img/up_right.svg';
import ClipboardImg from '../assets/img/clipboard.svg'
import { reducedAddress } from '../utils/utils';
import AuditIcon from '../assets/icons/Audit.svg'

export default function ClaimComponent(props) {
    const { item } = props;
    return (
        <div className='bg-[#121212] w-full min-w-[1560px] px-[20px] 2xl:px-[140px] pb-[16px]'>
            <div className='flex flex-row items-center justify-between w-full bg-[#1B1B1B] rounded-[16px] px-[40px] py-[20px]'>

                <div className='flex flex-row basis-2/12'>
                    <img src={item.token.icon} alt='ico' />
                    <div className='flex flex-col ml-[12px]'>
                        <p className='font-Inter-Regula1r text-[16px] text-[#FFF] text-[500]'>
                            {item.token.symbol}
                        </p>
                        <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[400]'>
                            {item.token.chain}
                        </p>
                    </div>
                </div>

                <div className='flex flex-col  basis-2/12'>
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] text-[500] '>
                        {item.amount.value}
                    </p>
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] text-[500] '>
                        {item.amount.price}
                    </p>
                </div>


                <div className='flex flex-col  basis-2/12'>
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] text-[500] '>
                        {item.allocatedAmount.state}
                    </p>
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] text-[500] '>
                        {item.allocatedAmount.value}
                    </p>
                </div>

                <div className='flex flex-col basis-2/12'>
                    <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[700]'>
                        {item.percentage.state}
                    </p>
                    <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[400]'>
                        {item.percentage.precent}
                    </p>
                </div>

                <div className='flex flex-row h-full items-center justify-between basis-2/12'>
                    <div className='flex flex-row items-center'>
                        <p className='font-Inter-Regular text-[16px] text-[#FFF] text-[500] mr-[6px]'>
                            {reducedAddress(item.tokenId)}
                        </p>
                        <img src={UpRightImg} alt='ico' />
                        <Button className='w-[38px] h-[40px] rounded-[6px] border-[1px] border-[#2C2C2C] flex items-center justify-center p-[12px] ml-[23px]'>
                            <img src={ClipboardImg} alt='ico' className='w-[13px] h-[16px]' />
                        </Button>
                    </div>

                </div>
                <div className='flex flex-row justify-center items-center basis-2/12'>
                    <img src={AuditIcon} alt='ico' />
                </div>
            </div>
        </div >

    )
}