import { Link, useLocation, useNavigate } from 'react-router-dom';
import FlashIcon from '../../assets/icons/flash.svg'
import AlarmIcon from '../../assets/icons/alarm.svg'
import DollarIcon from '../../assets/icons/dollar.svg'
import DownIcon from '../../assets/icons/arrow_down.svg'
import UKIcon from '../../assets/icons/uk.svg'
import FRIcon from '../../assets/icons/fr.svg'
import ConnectIcon from '../../assets/icons/folder.svg'
import { useState } from 'react';
import { Button } from '@material-tailwind/react';
import MenuIcon from '../../assets/icons/menu.svg'
import AvatarIcon from '../../assets/icons/avatar.svg'
import { ConfigProvider, theme, Dropdown, Space } from 'antd';
import { reducedAddress } from '../../utils/utils';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [curencyShow, setCurencyShow] = useState(false)
    const [langShow, setLangShow] = useState(false)
    const [langIdx, setLangIdx] = useState(0);
    const [currencyIdx, setCurrencyIdx] = useState(0);
    const currencyList = [
        {
            name: "USD",
        },
        {
            name: "USD",
        }
    ];
    const lanList = [
        {
            name: "UK",
            img: UKIcon,
        },
        {
            name: "FR",
            img: FRIcon,
        }
    ];

    const navs = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Claim",
            path: "/claim"
        },
        {
            name: "Account",
            path: "/account"
        },
        {
            name: "Connect Wallet",
            path: "/account?data=none"
        },
    ]

    const items = navs.map((nav, index) => ({
        label: <Link to={`${nav.path}`}>{nav.name}</Link>,
        key: index,
        value: nav,
    }));

    const { darkAlgorithm } = theme;

    return (
        <div className='sticky top-0 z-50 w-full bg-bg shadow'>

            <div className='flex flex-row py-[20px] px-[20px] lg:px-[150px] items-center justify-between'>
                <div className='flex flex-row  gap-6'>
                    <Link to="/" className='block'>
                        <div className="flex flex-row items-center gap-1">
                            <img src={FlashIcon} alt="flash" className='h-[39px]' />
                            <p className="text-white text-[19px]">Flash Dead</p>
                        </div>
                    </Link>

                </div>
                <div className='flex flex-row gap-4 items-center'>
                    <Button className='flex border border-stroke rounded-full h-[40px] w-[40px] p-3 cursor-pointer justify-center items-center'>
                        <img src={AlarmIcon} alt='alarm' />
                    </Button>
                    <div className="relative hidden md:flex">
                        <div
                            onClick={() => setLangShow(!langShow)}
                            className="border border-iconBg rounded-[30px] py-[5px] px-[18px] min-w-[64px] h-[40px] flex flex-row gap-[8px] items-center cursor-pointer justify-center"
                        >
                            <img src={lanList[langIdx].img} alt="icon" className="w-[20px] h-[20px]" />
                            <p className="flex text-white text-[14px] text-[500] ">{lanList[langIdx].name}</p>
                            <div className="flex flex-row justify-center items-center">
                                <img src={DownIcon} className={`${langShow && 'rotate-180'} w-[11px] h-[7px]`} alt="icon" />
                            </div>
                        </div>
                        {langShow && (
                            <>
                                <div className="fixed top-0 left-0 w-screen h-screen bg-transparent z-10" onClick={() => { setLangShow(false); }} />
                                <div className="bg-[#1B1B1B] flex flex-col rounded-lg absolute z-50 top-[40px] left-1 pb-2">
                                    {
                                        lanList.map((item, index) => {
                                            return (
                                                index !== langIdx &&
                                                < button
                                                    key={index}
                                                    className="flex flex-row py-[6px] px-[15px] w-[80px] border-b border-white items-center gap-[10px]"
                                                    onClick={() => { setLangShow(false); setLangIdx(index) }}
                                                >
                                                    <img src={lanList[index].img} alt="ico" className="w-[20px] h-[20px] rounded-[16px]" />
                                                    <p className="text-white text-[14px] text-[500]">{item.name}</p>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )}
                    </div>
                    <div className="relative hidden md:flex">
                        <div
                            onClick={() => setCurencyShow(!curencyShow)}
                            className="border border-iconBg rounded-[30px] py-[5px] px-[18px] min-w-[64px] h-[40px] flex flex-row gap-[8px] items-center cursor-pointer justify-center"
                        >
                            <img src={DollarIcon} alt="icon" className="w-[20px] h-[20px]" />
                            <p className="flex text-white text-[14px] text-[500] ">{currencyList[currencyIdx].name}</p>
                            <div className="flex flex-row justify-center items-center">
                                <img src={DownIcon} className={`${curencyShow && 'rotate-180'} w-[11px] h-[7px]`} alt="icon" />
                            </div>
                        </div>
                        {curencyShow && (
                            <>
                                <div className="fixed top-0 left-0 w-screen h-screen bg-transparent z-10" onClick={() => { setCurencyShow(false); }} />
                                <div className="bg-[#1B1B1B] flex flex-col rounded-lg absolute z-50 top-[40px] left-1 pb-2">
                                    {
                                        currencyList.map((item, index) => {
                                            return (
                                                index !== currencyIdx &&
                                                < button
                                                    key={index}
                                                    className="flex flex-row py-[6px] px-[15px] w-[80px] border-b border-white items-center gap-[10px]"
                                                    onClick={() => { setCurencyShow(false); setCurrencyIdx(index) }}
                                                >
                                                    <img src={DollarIcon} alt="ico" className="w-[20px] h-[20px] rounded-[16px]" />
                                                    <p className="text-white text-[14px] text-[500]">{item.name}</p>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )}
                    </div>
                    <Button className='hidden md:flex min-w-[64px] py-[10px] px-[15px] items-center justify-center cursor-pointer gap-2 rounded-[30px] bg-yellow1 shadow-md'
                        onClick={() => navigate('/account')}
                    >
                        <img src={ConnectIcon} alt='ico' />
                        <p className='text-black text-[14px] test-[500]'>{`${location.pathname === '/account' ? reducedAddress('0xb4aFb94d945B2C2b36635ED55De648649eA959C0') : 'Connect Wallet'}`}</p>
                    </Button>
                    {location.pathname === '/account' &&
                        <div className='' >
                            <img src={AvatarIcon} alt='ico' width={'40px'} height={'40px'} />
                        </div>
                    }
                    <ConfigProvider theme={{ algorithm: darkAlgorithm }}>

                        <Dropdown className="flex md:hidden bg-bg" menu={{ items }}>
                            <Space>
                                <Button className=''>
                                    <img src={MenuIcon} alt='ico' />
                                </Button>
                            </Space>
                        </Dropdown>
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
};

export default Header;