import {
    Tabs,
    TabsHeader,
    Tab,
} from "@material-tailwind/react";
export default function TabBar(props) {
    const { tabBar, setTabBar } = props;
    const data = [
        {
            label: "All transaction: 4",
            value: 0,
        },
        {
            label: "Testament: 1",
            value: 1,
        },
        {
            label: "To Claim: 2",
            value: 2,
        },
        {
            label: "Claimed: 3",
            value: 3,
        }

    ];
    return (
        <Tabs value={0} >
            <TabsHeader className='w-[650px] h-[60px] mt-[20px] bg-[#141414] border-[#2C2C2C] border-[1px] p-[8px]'
                indicatorProps={{
                    className:
                        "bg-[#FCBF07] rounded-[6px]",
                }}>
                {data.map(({ label, value }) => (
                    <Tab
                        onClick={() => setTabBar(value)}
                        key={value} value={value}
                        className={tabBar === value ? " text-[#32330E] font-[500] text-[16px] font-Inter-Bold" : " text-[16px] font-Inter-Regular text-[#86888C] font-[400] "} >
                        {label}
                    </Tab>
                ))}
            </TabsHeader>
        </Tabs >
    )
}