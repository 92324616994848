import NoDataIcon from '../assets/icons/no_data.svg'

const NoData = () => {
    return (
        <div className="flex bg-bg rounded-[16px] w-full h-[500px] items-center justify-center mt-4">
            <div className="flex flex-col">
                <img src={NoDataIcon} alt='ico' />
                <p className="text-[#323232] text-[18px] font-medium">No Data</p>
            </div>
        </div>
    )
}

export default NoData;