import HeaderSection from "../../components/HeaderSection";
import { dataSource } from "../../utils/constant";
import TimePane from "../../components/TimePane";
import Line from "../../components/Line";
import ClaimHeader from "../../components/ClaimHeader";
import ClaimComponent from "../../components/ClaimComponent";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export default function ClaimPage(props) {
    const navigate = useNavigate();
    return (
        <div className="bg-[#121212]">
            <HeaderSection account={false} />
            <TimePane />
            <div className="overflow-x-auto">
                <div className="flex flex-col px-[20px] 2xl:px-[150px] min-w-[1560px]">
                    <Line title='All Assets to claim' />
                </div>
                <ClaimHeader />
                {
                    dataSource.map((item, index) => {
                        return (<div key={index}>
                            <ClaimComponent item={item} />
                        </div>)
                    })
                }
                <div className="flex flex-col px-[20px] 2xl:px-[150px] mt-[120px] min-w-[1560px]">
                    <Line title='Note of the will' />
                    <div className="bg-bg w-full h-[400px] mt-[50px]"></div>

                    <div className="flex my-[40px]" onClick={() => navigate('/')}>
                        <Button className="py-[10px] px-[48px] border border-[#FBBF04] rounded-[10px] text-[#FBBF04] text-[13px] font-semibold">Back</Button>
                    </div>
                </div>
            </div>
        </div >
    )
}