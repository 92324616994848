import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HomePage from "../pages/main/HomePage";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import AccountPage from "../pages/main/AccountPage";
import ClaimPage from "../pages/main/ClaimPage";

export default function Router() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/account" element={<AccountPage />}></Route>
                <Route path="/claim" element={<ClaimPage />}></Route>
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}