const Line = ({
    title="All Assets to claim"
}) => {
    return (
        <div className="flex border-b-[1.5px] border-b-stroke w-full">
            <div className="flex border-b-4 border-b-primary drop-shadow-md">
                <p className="text-primary text-[16px] text-[500]">{title}</p>
            </div>
        </div>
    );
}

export default Line;