import SearchImg from "../assets/img/search.svg"

export default function Directory(props) {

    return (
        <div className="flex bg-[#121212] w-full px-[20px] lg:px-[140px] pt-[60px]">
            <div className="flex flex-col bg-[#1B1B1B] rounded-[16px] w-full px-[32px] py-[24px]">
                <p className="text-[16px] text-[#fff] font-Inter-Regular text-[600]">
                    Directory
                </p>
                <div className="flex flex-col relative mt-5">
                    <input
                        type="text"
                        onChange={(e) => console.log()}
                        className="w-full bg-[#141414] border outline-none border-[#2C2C2C] rounded-lg text-base text-[#86888C] py-4 px-[40px]"
                        placeholder="Find wills and your inheritance with name or address 0x"
                    />
                    <img
                        src={SearchImg}
                        alt="ico"
                        className="absolute top-[16px] left-[10px] w-6 h-6"
                    />
                </div>
            </div>
        </div>
    )
}