import { tableHeader } from '../utils/constant';

export default function TransactionHeader(props) {
    return (
        <div className='flex flex-row justify-between min-w-[1560px] bg-[#121212] w-full px-[40px] lg:px-[180px] pt-[40px] pb-[16px]'>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-2/12 uppercase'>
                {tableHeader[0].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-1/12 uppercase'>
                {tableHeader[1].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-1/12 uppercase'>
                {tableHeader[2].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-2/12 uppercase'>
                {tableHeader[3].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-2/12 uppercase'>
                {tableHeader[4].title}
            </p>
            <p className='text-[#86888C] text-[16px] font-Inter-Regular font-medium basis-3/12 uppercase'>
                {tableHeader[5].title}
            </p>
        </div>
    )
}