import AlarmIcon from '../assets/icons/alarm1.svg'

export default function ReminderComponent(props) {

    return (
        <div className="flex flex-col bg-bg rounded-[16px] px-[30px] py-[20px]">
            <p className="text-white text-[28px] font-semibold">Enable reminder</p>
            <div className="text-gray1 text-[18px] font-normal">A reminder is sent by email for the expiration of your will or when your claim is available!</div>
            <button className="flex px-[15px] w-[200px] py-[10px] justify-center items-center gap-2 mt-4 bg-primary rounded-[56px]">
                <img src={AlarmIcon} alt='ico' />
                <p className="text-black text-[16px] font-semibold">Active reminder</p>
            </button>
        </div>
    )
}