import EmailIcon from '../assets/icons/email.svg'
import PhoneIcon from '../assets/icons/phone.svg'
export default function UserInfoComponent(props) {

    return (
        <div className="flex bg-bg sm:flex-row flex-col rounded-[16px] sm:min-w-[550px] px-[40px] py-[35px] justify-between gap-[40px]">
            <div className="flex flex-col">
                <p className="text-white text-[28px] font-semibold">E-mail</p>
                <p className="text-gray1 text-[18px] font-normal">wally@gmail.com</p>
                <button className="flex px-[15px] w-[180px] py-[10px] mt-8 justify-center items-center gap-1 bg-primary rounded-[56px]">
                    <img src={EmailIcon} alt='ico' />
                    <p className="text-black text-[16px] font-semibold">Change e-mail</p>
                </button>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-[28px] font-semibold">SMS</p>
                <p className="text-gray1 text-[18px] font-normal">+33758900000</p>
                <button className="flex px-[15px] w-[230px] py-[10px] mt-8 justify-center items-center gap-2 bg-primary rounded-[56px]">
                    <img src={PhoneIcon} alt='ico' />
                    <p className="text-black text-[16px] font-semibold">Change phone number</p>
                </button>
            </div>
        </div>
    )
}