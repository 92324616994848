import HomeImg1 from "../assets/img/logo_3.png"
import HomeImg2 from "../assets/img/logo_2.png"
import HomeShadow from "../assets/img/logo_4.png"
import EthLogo from "../assets/img/Ethereum_logo.png"
import Logo5 from "../assets/img/logo_5.png"
import BtcLogo from "../assets/img/btc_ico.png"
import ClsLogo from "../assets/img/cls.png"

export default function HeaderSection(props) {
    const { account } = props;

    return (
        <div className="bg-[url('./assets/img/back.png')] object-fill h-[380px] w-full py-10">
            <div className="hidden 2xl:flex">
                <img src={HomeShadow} alt="shadow" className='absolute top-[105px] right-[95px]' />
                <img src={HomeImg2} alt={"home1"} className="absolute top-[105px] right-[422px]" />
                <img src={HomeImg1} alt={"home2"} className="absolute top-[105px] right-[0px]" />
                <img src={EthLogo} alt={"eth"} className="absolute top-[250px] right-[541px]" />
                <img src={Logo5} alt={"logo"} className="absolute top-[140px] right-[182px]" />
                <img src={ClsLogo} alt={"logo"} className="absolute top-[335px] right-[520px]" />
                <img src={BtcLogo} alt={"logo"} className="absolute top-[335px] right-[520px]" />
            </div>
            <div className="flex flex-row justify-between items-center h-full">
                <div className="ml-[20px] lg:ml-[170px]">
                    <p className="lg:w-[700px] w-max tracking-widest text-[#fff] text-[36px] sm:text-[60px] text-[700] font-Inter-Bold ">
                        {account === false ? "FLASH DEAD" : "ACCOUNT PAGE"}
                    </p>
                    <p className="w-[300px] sm:w-[500px] text-[#fff] text-[16px] text-[400] font-Inter-Regular">
                        {account === false ? "1st Dapps in the decentralized world to easily transfer your funds to your heirs. Flash Dead website is a directory to see your wills and wallets to claim." :
                            "Configure and view your profile"}
                    </p>
                </div>
            </div>
        </div>
    )
}