import EtherIcon from '../assets/icons/Ethereum.svg'
import BnbIcon from '../assets/icons/BinanceCoin.svg'
import AddrAvatar1 from '../assets/img/AddrAvatar1.png'
import AddrAvatar2 from '../assets/img/avatar1.svg'
import AddrAvatar3 from '../assets/img/AddrAvatar3.png'
import TypeIcon from '../assets/icons/type1.svg'
import UsdtIcon from '../assets/icons/Tether.svg'
import BtcIcon from '../assets/icons/Bitcoin.svg'
import SolIcon from '../assets/icons/Solana.svg'
import AvaxIcon from '../assets/icons/Avalanche.svg'

export const tableHeader = [
    {
        title: 'Date & Network',
        key: '1',
    },
    {
        title: 'Type',
        key: '2',
    }, {
        title: 'Timing End',
        key: '3',
    }, {
        title: 'Name & Total Value',
        key: '4',
    }, {
        title: 'Heir Address',
        key: '5',
    }, {
        title: 'Transaction Id',
        key: '6',
    },
]
export const tableContent1 = [
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: BnbIcon,
            symbol: "18 Feb, 2023",
            chain: "Binance Smart Chain"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar1,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: BnbIcon,
            symbol: "18 Feb, 2023",
            chain: "Binance Smart Chain"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar3,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
]
export const tableContent2 = [
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
]
export const tableContent3 = [
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: BnbIcon,
            symbol: "18 Feb, 2023",
            chain: "Binance Smart Chain"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar1,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
]
export const tableContent4 = [
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: BnbIcon,
            symbol: "18 Feb, 2023",
            chain: "Binance Smart Chain"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar1,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
    {
        date: {
            icon: EtherIcon,
            symbol: "18 Feb, 2023",
            chain: "Etherium"
        },
        type: {
            icon: TypeIcon,
            value: 'Testament'
        },
        timeEnd: '01/02/21',
        totalValue: {
            name: 'Wally Whill',
            value: '$67.56'
        },
        address: {
            icon: AddrAvatar2,
            address: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
        },
        transaction: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800'
    },
]
export const columns = [
    {
        title: 'Tokens',
        key: 'Tokens',
    },
    {
        title: 'Amount',
        key: 'Amount',
    }, {
        title: 'Amount Allocated',
        key: 'Amount Allocated',
    }, {
        title: 'Percentage Allocated',
        key: 'Percentage Allocated',
    }, {
        title: 'Token Id',
        key: 'Token Id',
    }, {
        title: 'Flash Audit',
        key: 'Flash Audit',
    },
]
export const dataSource = [
    {
        token: {
            icon: EtherIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    },
    {
        token: {
            icon: UsdtIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    }, {
        token: {
            icon: BtcIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    }, {
        token: {
            icon: BnbIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    }, {
        token: {
            icon: SolIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    }, {
        token: {
            icon: AvaxIcon,
            symbol: "Weth",
            chain: "Etherium"
        },
        amount: {
            value: '0.7546',
            price: '$1454.03'
        },
        allocatedAmount: {
            state: 'Available:',
            value: '0.22638'
        },
        percentage: {
            state: '% Assigned',
            precent: '100%'
        },
        tokenId: '0x6b08236cd4F2BE7F49B0201d23e5ba29e06CF800',
    },
]