import { Button } from '@material-tailwind/react';
import PhoneImg from '../assets/img/phone.png'

export default function DownloadWallet(props) {
    return (
        <div className=' relative w-full px-[20px] 2xl:px-[235px] mt-[500px] pb-[130px]'>
            <div className='flex flex-col lg:flex-row justify-between items-center w-full h-[560px] sm:h-[700px] lg:h-[415px] rounded-[23px] bg-[#1B1B1B]'>
                <div className='flex lg:basis-2/5 justify-center w-full h-[500px] border-b lg:border-none border-b-gray1'>
                </div>
                <img src={PhoneImg} className='absolute bottom-[400px] sm:bottom-[420px] lg:bottom-[130px]' alt='ico' />
                <div className='flex flex-col items-center lg:items-start py-10 lg:py-0 lg:basis-3/5'>
                    <p className='text-[#fff] font-Inter-Regular text-[28px] sm:text-[42px]'>
                        Download Flash Wallet
                    </p>
                    <p className='text-[#86888C] font-Inter-Regular text-[16px] text-center w-[300px] sm:w-[450px] mt-[20px]'>
                        1st Dapps in the decentralized world to easily transfer your funds to your heirs.
                    </p>
                    <div className='flex flex-row mt-[28px]'>
                        <Button className="bg-[url('./assets/img/google.svg')] bg-cover w-[155px] h-[45px]">{` `}</Button>
                        <Button className="bg-[url('./assets/img/appstore.svg')] bg-cover w-[155px] h-[45px] ml-[20px]">{' '}</Button>
                    </div>
                </div>
            </div>
        </div>

    )
}