import { Button } from '@material-tailwind/react';
import CalendarImg from '../assets/img/calendar.svg';
import UpRightImg from '../assets/img/up_right.svg';
import RightImg from '../assets/img/right.svg'
import ClipboardImg from '../assets/img/clipboard.svg'
import { reducedAddress } from '../utils/utils';
import { useNavigate } from 'react-router-dom';

export default function TransactionComponent(props) {
    const { item } = props;
    const navigate = useNavigate()
    return (
        <div className='bg-[#121212] w-full min-w-[1560px] overflow-x-auto px-[20px] lg:px-[140px] pb-[16px]'>
            <div className='flex flex-row items-center justify-between w-full bg-[#1B1B1B] rounded-[16px] px-[40px] py-[20px]'>
                <div className='flex flex-row basis-2/12'>
                    <img src={item.date.icon} alt='ico' />
                    <div className='flex flex-col ml-[12px]'>
                        <p className='font-Inter-Regula1r text-[16px] text-[#FFF] font-medium'>
                            {item.date.symbol}
                        </p>
                        <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[400]'>
                            {item.date.chain}
                        </p>
                    </div>
                </div>

                <div className='flex flex-row items-center basis-1/12'>
                    <img src={item.type.icon} alt='ico' />
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] font-medium ml-[12px]'>
                        {item.type.value}
                    </p>
                </div>


                <div className='flex flex-row items-center basis-1/12'>
                    <img src={CalendarImg} alt='ico' />
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] font-medium ml-[12px]'>
                        {item.timeEnd}
                    </p>
                </div>

                <div className='flex flex-col basis-2/12'>
                    <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[700]'>
                        {item.totalValue.name}
                    </p>
                    <p className='font-Inter-Regular text-[14px] text-[#86888C] text-[400]'>
                        {item.totalValue.value}
                    </p>
                </div>

                <div className='flex flex-row items-center basis-2/12'>
                    <img src={item.address.icon} alt='ico' />
                    <p className='font-Inter-Regular text-[16px] text-[#FFF] font-medium ml-[12px]'>
                        {reducedAddress(item.address.address)}
                    </p>
                </div>
                <div className='flex flex-row h-full items-center justify-between basis-3/12'>
                    <div className='flex flex-row '>
                        <p className='font-Inter-Regular text-[16px] text-[#FFF] font-medium mr-[6px]'>
                            TxID: {reducedAddress(item.transaction)}
                        </p>
                        <img src={UpRightImg} alt='ico' />
                    </div>

                    <Button className='w-[38px] h-[40px] rounded-[6px] border-[1px] border-[#2C2C2C] flex items-center justify-center p-[12px]'>
                        <img src={ClipboardImg} className='w-[13px] h-[16px]' alt='ico' />
                    </Button>
                    <div className='w-[1px] h-[64px]  bg-[#2C2C2C]'>
                    </div>
                    <Button className='w-[36px] h-[36px] bg-transparent p-0 rounded-full' onClick={() => navigate('/claim')}>
                        <img src={RightImg} alt='ico' />
                    </Button>
                </div>
            </div>
        </div>

    )
}